import { createRouter, createWebHistory } from 'vue-router'

import { COMPANY } from '@/constants/global-constants'
import { useLayoutStore } from '@/store/modules/layout.store'

const routes = [
  {
    path: '/',
    name: 'BaseLayout',
    component: () => import('../layouts/BaseLayout.vue'),
    redirect: { name: 'Authorization' },
    children: [
      {
        path: '/auth',
        name: 'Authorization',
        component: () => import('../pages/Authorization.vue'),
        meta: { title: 'Вход в РЕХАУ' },
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('../pages/Registration.vue'),
        meta: { title: 'Регистрация в РЕХАУ' },
      },
      {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('../pages/ResetPassword.vue'),
      },
      {
        path: '/check-code',
        name: 'CheckCode',
        component: () => import('../pages/CheckCode.vue'),
      },
      {
        path: '/email-confirm',
        name: 'ConfirmEmail',
        component: () => import('../pages/ConfirmEmail.vue'),
      },
    ],
  },
  {
    path: '/profile',
    name: 'UserLayout',
    component: () => import('../layouts/UserLayout.vue'),
    meta: { title: 'Личный кабинет РЕХАУ' },
    children: [
      {
        path: '',
        name: 'UserProfile',
        component: () => import('../pages/UserProfile.vue'),
        meta: { title: 'Личный кабинет РЕХАУ' },
      },
    ],
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router

router.beforeEach(async (to) => {
  // очищаем ошибки перед переходом на другую страницу
  useLayoutStore().setError(null, null)

  if (to.name !== 'Register' && sessionStorage.getItem('registrationForm')) {
    sessionStorage.removeItem('registrationForm')
  }

  if (to.name !== 'ResetPassword' && to.name !== 'CheckCode' && sessionStorage.getItem('resetPassword')) {
    sessionStorage.removeItem('resetPassword')
  }

  if (to.name !== 'CheckCode' && sessionStorage.getItem('confirmationData')) {
    sessionStorage.removeItem('confirmationData')
  }

  const tokenInfo = localStorage.getItem('token')

  if (tokenInfo && (to.name === 'Authorization' || to.name === 'Registration')) {
    return { name: 'UserProfile' }
  } else if (!tokenInfo && to.name === 'UserProfile') {
    return { name: 'Authorization' }
  }

  document.title = to.meta.title || `Вход в ${COMPANY.name}`
})
