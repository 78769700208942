import axios from 'axios'

import { AppConfig } from '@/configs/app.config'
import router from '@/router'
import { useLayoutStore } from '@/store/modules/layout.store'

class Request {
  axiosInstance = null

  init() {
    this.axiosInstance = axios.create({
      baseURL: AppConfig.backUrl,
      timeout: 30000,
    })

    this.axiosInstance.interceptors.request.use(config => {
      // подставляем заголовок авторизации, если есть токен
      // для /password-change свои правила, костыль
      const token = localStorage.getItem('token')
      if (token && !config.headers.Authorization && config.url !== '/password-change') {
        const parsedToken = JSON.parse(token)
        config.headers.Authorization = `Bearer ${parsedToken.access_token}`
      }
      return config
    })

    this.axiosInstance.interceptors.response.use(
      (response) => {
        // обнуляем ошибку, если получили ответ
        useLayoutStore().setError(null, null)
        return response
      },
      (error) => {
        return this.errorHandler(error)
      },
    )
  }

  get axios() {
    return this.axiosInstance
  }

  async errorHandler(error) {
    if (error.response.status >= 500) {
      useLayoutStore().setError(error.response.status, error.response.data?.message || error.response.data?.error)
    } else if (error.response.status === 401) {
      localStorage.removeItem('token')
      await router.push({ name: 'Authorization' })
    } else {
      throw error
    }
  }
}

export const request = new Request()
