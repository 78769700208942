import { defineStore } from 'pinia'

export const useLayoutStore = defineStore('layout', {
  state: () => ({
    error: {
      code: null,
      text: null,
    },
  }),
  actions: {
    setError(code = null, text = null) {
      this.error.code = code
      this.error.text = text
    },
  },
})
