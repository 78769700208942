<template>
  <router-view />
</template>

<script setup>
import { request } from '@/requests/request'

request.init()
</script>

<style lang="scss"></style>
